import { vm } from "@/main";
import {URLSearchParams} from "core-js/modules/web.url-search-params";

class Search {

    static getCurrentPage() {
      const page = new URLSearchParams(window.location.search).get('page');
      return page ? page : 1;
    }

    static getSearchQuery() {
      const search = new URLSearchParams(window.location.search).get('q');
      if (!search || search.length < 1) {
        return false;
      }
      return search;
    }

    static changePage(currentPage) {
      if (history.pushState) {
          let searchParams = new URLSearchParams(window.location.search);
          searchParams.set("page", currentPage);
          let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
          window.history.pushState({path: newurl}, '', newurl);
      }
    }

    static clearPage() {
      if (history.pushState) {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.delete('page')
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({path: newurl}, '', newurl);
      }
    }

    static addQuery(name, value) {
      if (history.pushState) {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set(name, value);
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({path: newurl}, '', newurl);
      }
    }

    static addQueryArray(values, clearPage) {
      if (history.pushState) {
        let searchParams = new URLSearchParams(window.location.search);
        if (searchParams.get('page') && clearPage) {
          searchParams.delete('page')
        }
        values.forEach(value => {
          Object.keys(value).map(item => searchParams.set(item, value[item]))
        });

        let newurl = '?' + searchParams.toString();
        // vm.config.globalProperties.$router.push(newurl);
        window.history.pushState({path: newurl}, '', newurl);
      }
    }

    static removeQuery(name) {
      if (history.pushState) {
        let searchParams = new URLSearchParams(window.location.search);
        if (searchParams.get(name)) {
          searchParams.delete(name)
          let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
          window.history.pushState({path: newurl}, '', newurl);
        }
      }
    }

    static getQueryParam(name) {
      const param = new URLSearchParams(window.location.search).get(name);
      if (!param || param.length < 1) {
        return '';
      }
      return param;
    }

}

export default Search
