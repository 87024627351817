<template>
  <Content>
    <template v-slot:topbar>
      <AddCatalogParam />
    </template>

    <template v-slot:content>
      <template v-if="params && params.data.length > 0">
        <div class="sm:rounded-md mb-3">
          <ul role="list" class="">
            <template v-for="(item, index) in params.data" :key="index">
              <SingleCatalogParam :param="item" />
            </template>
          </ul>
        </div>

        <Pagination :meta="params.meta" :onPageChange="onPageChange" />
      </template>

      <template v-else>
        <p class="py-6 text-center dark:text-gray-300">
          Diemžēl nekas netika atrasts
        </p>
      </template>
    </template>
  </Content>
</template>

<script>
import { debounce } from 'vue-debounce'
import {mapGetters} from "vuex";
import Search from "@/services/Helpers/search";

import { defineAsyncComponent } from 'vue'

const AddCatalogParam = defineAsyncComponent(() =>
  import('@/components/Settings/CatalogParam/AddCatalogParam'))
const SingleCatalogParam = defineAsyncComponent(() =>
  import('@/components/Settings/CatalogParam/SingleCatalogParam'))
const Pagination = defineAsyncComponent(() =>
  import('@/components/Components/Pagination'))

export default {
  name: "CatalogParams",
  components: {
    AddCatalogParam,
    SingleCatalogParam,
    Pagination,
  },
  created() {
    this.$store.dispatch("setDataLoading", true)
  },
  mounted() {
    this.$store.dispatch('removeAllFormsForDisplay')
    this.$store.dispatch('resetSearch')
    const s = Search.getSearchQuery();
    if (s) {
      this.$store.dispatch('changeSearch', s)
      this.searchParams()
    } else {
      this.$store.dispatch('getParams', Search.getCurrentPage())
    }
  },
  computed: {
    ...mapGetters({
      params: 'params',
      formsForDisplay: 'formsForDisplay',
      editableItem: 'editableItem',
      search: 'search',
    })
  },
  watch: {
    search: function () {
      this.searchParams()
    }
  },
  methods: {
    searchParams: debounce(function () {
      this.$store.dispatch('changeSearch', this.search)
      this.$store.dispatch('getParams', Search.getCurrentPage())
    }, 500),
    onPageChange(page) {
      this.$store.dispatch('getParams', page);
      Search.changePage(this.params.meta.current_page)
    },
    showForm(formName) {
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName) {
      return this.formsForDisplay.includes(formName)
    },
    hideAddBankForm() {
      this.$store.dispatch('removeAllFormsForDisplay')
    },
    editItem(item) {
      this.$store.dispatch('setEditableItem', item)
    },
    deleteParam(itemId) {
      if (confirm('Vai esi drošs?')) {
        this.$Progress.start()
        this.$store.dispatch('deleteParam', itemId)
      }
    }
  }
}
</script>